import { useErrorHandling } from '@/composables'
import { ENDPOINTS } from '@/endpoints'
import { type APIResponse } from '@/types'
import type {
    MarketDataExternalSectorCurrentAccount,
    MarketDataExternalSectorDerivatives,
    MarketDataExternalSectorDirectInvestments,
    MarketDataExternalSectorExports,
    MarketDataExternalSectorFinancialAccount,
    MarketDataExternalSectorImports,
    MarketDataExternalSectorOtherInvestments,
    MarketDataExternalSectorPortfolioInvestments,
    MarketDataExternalSectorReserves,
} from '@/types'
import axios, { type GenericAbortSignal } from 'axios'

export const getMarketDataExternalSectorExports = async (signal?: GenericAbortSignal) => {
    const request = axios.get<APIResponse<MarketDataExternalSectorExports>>(
        `${ENDPOINTS.MARKET_DATA_V1}/external-sector/exports`,
        { signal, suppressToast: !!signal },
    )

    const response = await useErrorHandling(request)

    return response.data.data
}

export const getMarketDataExternalSectorExportsYoy = async (signal?: GenericAbortSignal) => {
    const request = axios.get<APIResponse<MarketDataExternalSectorExports>>(
        `${ENDPOINTS.MARKET_DATA_V1}/external-sector/exports-yoy`,
        { signal, suppressToast: !!signal },
    )

    const response = await useErrorHandling(request)

    return response.data.data
}

export const getMarketDataExternalSectorImports = async (signal?: GenericAbortSignal) => {
    const request = axios.get<APIResponse<MarketDataExternalSectorImports>>(
        `${ENDPOINTS.MARKET_DATA_V1}/external-sector/imports`,
        { signal, suppressToast: !!signal },
    )

    const response = await useErrorHandling(request)

    return response.data.data
}

export const getMarketDataExternalSectorImportsYoy = async (signal?: GenericAbortSignal) => {
    const request = axios.get<APIResponse<MarketDataExternalSectorImports>>(
        `${ENDPOINTS.MARKET_DATA_V1}/external-sector/imports-yoy`,
        { signal, suppressToast: !!signal },
    )

    const response = await useErrorHandling(request)

    return response.data.data
}

export const getMarketDataExternalSectorCurrentAccount = async (signal?: GenericAbortSignal) => {
    const request = axios.get<APIResponse<MarketDataExternalSectorCurrentAccount>>(
        `${ENDPOINTS.MARKET_DATA_V1}/external-sector/current-account`,
        { signal, suppressToast: !!signal },
    )

    const response = await useErrorHandling(request)

    return response.data.data
}

export const getMarketDataExternalSectorCurrentAccountPerGdp = async (
    signal?: GenericAbortSignal,
) => {
    const request = axios.get<APIResponse<MarketDataExternalSectorCurrentAccount>>(
        `${ENDPOINTS.MARKET_DATA_V1}/external-sector/ca-per-gdp`,
        { signal, suppressToast: !!signal },
    )

    const response = await useErrorHandling(request)

    return response.data.data
}

export const getMarketDataExternalSectorFinancialAccount = async (signal?: GenericAbortSignal) => {
    const request = axios.get<APIResponse<MarketDataExternalSectorFinancialAccount>>(
        `${ENDPOINTS.MARKET_DATA_V1}/external-sector/financial-account`,
        { signal, suppressToast: !!signal },
    )

    const response = await useErrorHandling(request)

    return response.data.data
}

export const getMarketDataExternalSectorDerivatives = async (signal?: GenericAbortSignal) => {
    const request = axios.get<APIResponse<MarketDataExternalSectorDerivatives>>(
        `${ENDPOINTS.MARKET_DATA_V1}/external-sector/derivatives`,
        { signal, suppressToast: !!signal },
    )

    const response = await useErrorHandling(request)

    return response.data.data
}

export const getMarketDataExternalSectorOtherInvestments = async (signal?: GenericAbortSignal) => {
    const request = axios.get<APIResponse<MarketDataExternalSectorOtherInvestments>>(
        `${ENDPOINTS.MARKET_DATA_V1}/external-sector/other-investment`,
        { signal, suppressToast: !!signal },
    )

    const response = await useErrorHandling(request)

    return response.data.data
}

export const getMarketDataExternalSectorReserves = async (
    year?: string,
    signal?: GenericAbortSignal,
) => {
    const request = axios.get<APIResponse<MarketDataExternalSectorReserves>>(
        `${ENDPOINTS.MARKET_DATA_V1}/external-sector/reserves?year=${year}`,
        { signal, suppressToast: !!signal },
    )

    const response = await useErrorHandling(request)

    return response.data.data
}

export const getMarketDataExternalSectorPortfolioInvestments = async (
    signal?: GenericAbortSignal,
) => {
    const request = axios.get<APIResponse<MarketDataExternalSectorPortfolioInvestments[]>>(
        `${ENDPOINTS.MARKET_DATA_V1}/external-sector/portfolio-investment`,
        { signal, suppressToast: !!signal },
    )

    const response = await useErrorHandling(request)

    return response.data.data
}

export const getMarketDataExternalSectorDirectInvestments = async (signal?: GenericAbortSignal) => {
    const request = axios.get<APIResponse<MarketDataExternalSectorDirectInvestments[]>>(
        `${ENDPOINTS.MARKET_DATA_V1}/external-sector/direct-investment`,
        { signal, suppressToast: !!signal },
    )

    const response = await useErrorHandling(request)

    return response.data.data
}

export const getMarketDataExternalSectorNet = async (signal?: GenericAbortSignal) => {
    const request = axios.get<APIResponse<MarketDataExternalSectorImports>>(
        `${ENDPOINTS.MARKET_DATA_V1}/external-sector/net`,
        { signal, suppressToast: !!signal },
    )

    const response = await useErrorHandling(request)

    return response.data.data
}
