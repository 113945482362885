import { importHighcharts } from '@/lib/highcharts'
import { BlockModule } from '../common'
import { doIfInViewOrDefer } from './common'
import { getMarketDataPopulationAgeGender } from '@/api'
import {
    computed,
    defineAsyncComponent,
    defineComponent,
    onBeforeUnmount,
    onMounted,
    ref,
    unref,
    watch,
} from 'vue'
import type { Chart, SeriesOptionsType } from 'highcharts'
import type { GenderSeries } from '@/types'
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select'
import { computedAsync } from '@vueuse/core'
import { format } from 'date-fns'
import { useBlockContext } from '../common/composables'

new BlockModule({
    selector: '.block-market-data-population-age-gender',
    vueComponent: defineComponent({
        components: {
            ChartShareButtons: defineAsyncComponent(
                () => import('@/components/website/ChartShareButtons.vue'),
            ),
            ShadcnSelect: Select,
            SelectContent,
            SelectGroup,
            SelectItem,
            SelectLabel,
            SelectTrigger,
            SelectValue,
        },
        props: {
            year: String,
        },
        setup(props) {
            const chartContainerRef = ref<HTMLElement>()

            const year = ref(props.year)

            let chartInstance: Chart | null = null

            const blockContext = useBlockContext()

            const sortByAgeGroup = (a: GenderSeries, b: GenderSeries) => {
                if (a.ageGroup === 'overall') return -100

                const aAgeMin = parseInt(a.ageGroup.split('-')[0])
                const bAgeMin = parseInt(b.ageGroup.split('-')[0])
                return aAgeMin - bAgeMin
            }

            const dataAsync = computedAsync(async (onCancel) => {
                const abortController = new AbortController()

                onCancel(() => abortController.abort())

                const data = await getMarketDataPopulationAgeGender(
                    unref(year),
                    abortController.signal,
                )

                const { maleSeries, femaleSeries } = data

                maleSeries.sort(sortByAgeGroup)
                femaleSeries.sort(sortByAgeGroup)

                return data
            })

            const lastUpdatedLabel = computed(() => {
                if (!dataAsync.value) return
                return format(dataAsync.value.lastUpdated, 'PP')
            })

            const seriesData = computed<SeriesOptionsType[]>(() => {
                if (!dataAsync.value) return []
                return [
                    {
                        type: 'bar',
                        name: 'Male',
                        data: dataAsync.value.maleSeries
                            .filter((x) => x.ageGroup !== 'overall')
                            .map((item) => item.data * -1),
                    },
                    {
                        type: 'bar',
                        name: 'Female',
                        data: dataAsync.value.femaleSeries
                            .filter((x) => x.ageGroup !== 'overall')
                            .map((item) => item.data),
                    },
                ]
            })

            const categories = computed(() => {
                if (!dataAsync.value) return []

                return [
                    ...new Set([
                        ...dataAsync.value.maleSeries.map((item) => item.ageGroup),
                        ...dataAsync.value.femaleSeries.map((item) => item.ageGroup),
                    ]),
                ]
            })

            const setupChart = async () => {
                const chartEl = chartContainerRef.value
                if (!chartEl) return

                const Highcharts = await importHighcharts()

                chartInstance = Highcharts.chart({
                    chart: {
                        type: 'bar',
                        renderTo: chartEl as HTMLElement,
                    },
                    title: {
                        text: '',
                    },
                    series: [],
                    xAxis: [
                        {
                            categories: [],
                            reversed: false,
                        },
                        {
                            // mirror axis on right side
                            opposite: true,
                            reversed: false,
                            categories: [],
                            linkedTo: 0,
                        },
                    ],
                    yAxis: {
                        title: {
                            text: 'Population (000)',
                        },
                        labels: {
                            format: '{abs value}',
                        },
                    },
                    plotOptions: {
                        series: {
                            stacking: 'normal',
                            // borderRadius: '50%',
                        },
                    },
                    tooltip: {
                        format:
                            '<b>{series.name} ({point.category})</b><br/>' +
                            'Population: {(abs point.y):.2f}',
                    },
                    exporting: {
                        enabled: false,
                        filename: 'population-age',
                    },
                    legend: { enabled: false },
                })
            }

            const onDownload = () => {
                chartInstance?.downloadCSV()
            }

            const updateChart = () => {
                chartInstance?.update(
                    {
                        series: seriesData.value,
                        xAxis: [
                            {
                                categories: categories.value,
                                reversed: false,
                            },
                            {
                                // mirror axis on right side
                                opposite: true,
                                reversed: false,
                                categories: categories.value,
                                linkedTo: 0,
                            },
                        ],
                    },
                    true,
                    true,
                    true,
                )
            }

            onMounted(async () => {
                await setupChart()

                if (blockContext?.isInView) {
                    updateChart()
                }
            })

            onBeforeUnmount(() => chartInstance?.destroy())

            watch(
                () => ({ series: seriesData.value, categories: categories.value }),
                () => {
                    if (!chartInstance) return
                    updateChart()
                },
            )

            if (blockContext && !blockContext.isInView) {
                doIfInViewOrDefer(() => {
                    updateChart()
                }, blockContext)
            }

            return {
                year,
                chartContainerRef,
                lastUpdatedLabel,
                onDownload,
            }
        },
    }),
})
